import type { UploadImageType } from '../common'

export const provinceQueryKey = 'provinceQueryKey'

export const postsQueryKey = 'posts'

export const postQueryKey = 'post'

export const postDistrictQueryKey = 'district_post'

export const districtQueryKey = 'districtQueryKey'

export const wardQueryKey = 'wardQueryKey'

export type PostsPagingQuery = {
  page: number
  limit: number
  name?: string
  province_code?: number
  district_code?: number
  ward_code?: number
  type?: string
  is_verified?: string
  is_block?: number
  price_start?: number
  price_end?: number
  acreageStart?: number
  acreageEnd?: number
  intersect?: boolean
}

export type CompareAroundAreaQuery = Omit<PostsPagingQuery, 'page' | 'limit'>

export type PostSellerType = {
  id: string
  title: string
  slug: string
  address: string | null
  lat: number
  lang: number
  type: number
  point: number
  province: {
    name: string
    code: number
  }
  district: {
    name: string
    code: number
  }
  ward: {
    name: string
    code: number
  }
  street: string | null
  is_verified: number
  is_owner: number
  note_verified: string | null
  images: UploadImageType[]
  images_owner: UploadImageType[]
  youtube_url: string | null
  content: string
  acreage: string
  facade: number | null
  num_way_in: number
  price: string
  unit: number
  price_square_metre: string | null
  direction_house: string | null
  direction_balcony: string | null
  num_bedroom: number
  num_road_house: number | null
  num_floors: number
  num_bathroom: number
  furniture: {
    code: string
    name: string
  }
  house_owning_document: {
    code: string
    name: string
  }
  contact_name: string
  contact_phone: string
  contact_mail: string
  code: string
  created_at: string
  contact_id: string
  contact_total_post_estate: number
}

export type CompareAroundAreaType = {
  code: number
  name: string
  popular_average_price: number
  total_post_estate: number
}

export enum UnitPost {
  VND = 1,
  PRICE = 2,
  DEAL = 3
}
