export const blogsInfiniteQueryKey = 'blogsInfiniteQueryKey';
export const blogsQueryKey = 'blogsQueryKey';
export const categoryClientQueryKey = 'categoryClientQueryKey';
export const spotlightQueryKey = 'spotlightQueryKey';
export const CTE_TIN_BAT_BAT_DONG_SAN = 'CTE_TIN_BAT_BAT_DONG_SAN';
export const CTE_KIEN_THUC_TAI_CHINH = 'CTE_KIEN_THUC_TAI_CHINH';
export const CTE_KINH_TE_TAI_CHINH = 'CTE_KINH_TE___TAI_CHINH';
export type BlogsPagingQuery = {
  page: number;
  limit: number;
  category_id?: string;
  title?: string;
  is_status?: number;
  code?: string;
  type?: number;
  intersect?: boolean;
};
export type BlogType = {
  id: string;
  category_id: string;
  link_image_content: string;
  description: string;
  title: string;
  content: string | null;
  link_image_copy_right: string;
  link_image_device: string;
  link_active: string;
  is_status: number;
  published: string;
  created_at: string;
  created_by: string;
  modify_at: string;
  modify_by: string | null;
};
export type CategoryClient = {
  code: string;
  name: string;
  id: string;
};
export enum TypeBlogEnum {
  TEXT = 1,
  VIDEO = 2,
}
export type NewsCategoryQuery = {
  category_id?: string;
  type?: number;
  code?: string;
};